import { Gltf, useGLTF } from "@react-three/drei"
import { forwardRef } from "react"
import { BackSide, FrontSide, DoubleSide } from "three"

export const Landscape = forwardRef((props, ref) => {
    const { nodes, materials } = useGLTF(
        "/images/Landscape.glb",
        "/draco-gltf/"
    )
    nodes.Landscape.material.transparent = true
    return (
        <>
            <mesh ref={ref} geometry={nodes.Landscape.geometry}></mesh>

            <mesh
                scale={[0.99, 0.99, 0.99]}
                geometry={nodes.Landscape.geometry}
            >
                <meshPhysicalMaterial
                    color="#350005"
                    roughness={0.6}
                    thickness={0.5}
                    side={BackSide}
                />
            </mesh>

            <mesh scale={[0.1, 0.1, 0.1]} geometry={nodes.Landscape.geometry}>
                <meshPhysicalMaterial
                    color="black"
                    roughness={0}
                    thickness={0.5}
                    side={DoubleSide}
                />
            </mesh>
        </>
    )
})
