import React, { useEffect, useRef, forwardRef } from "react"
import * as THREE from "three"
import Perlin from "perlin.js"

Perlin.seed(Math.random())

export const LovelyPlain = forwardRef((props, ref) => {
    const geom = useRef()

    useEffect(() => {
        const vertex = new THREE.Vector3()
        const normal = new THREE.Vector3()
        let newPositionAttribute = []
        const positionAttribute = geom.current.getAttribute("position")
        const normalAttribute = geom.current.getAttribute("normal")
        for (let i = 0; i < positionAttribute.count; i++) {
            vertex.fromBufferAttribute(positionAttribute, i)
            normal.fromBufferAttribute(normalAttribute, i)
            const v = vertex.multiplyScalar(0.5)
            const n = Perlin.simplex3(...v.toArray())
            vertex.add(normal.multiplyScalar(n * 0.3))
            newPositionAttribute.push(vertex.x, vertex.y, vertex.z)
        }
        geom.current.setAttribute(
            "position",
            new THREE.Float32BufferAttribute(newPositionAttribute, 3)
        )
        geom.current.attributes.position.needsUpdate = true
        geom.current.computeVertexNormals()
    }, [])

    return (
        <mesh ref={ref}>
            <planeGeometry args={[5, 5]} ref={geom}>
                <meshBasicMaterial color="#221600" />
            </planeGeometry>
        </mesh>
    )
})
