import { MeshWobbleMaterial, useGLTF, useTexture } from "@react-three/drei"
import { LayerMaterial, Texture } from "lamina"
import { forwardRef } from "react"
import { DoubleSide } from "three"

export const Hand = forwardRef((props, ref) => {
    const { nodes } = useGLTF("/images/hand.glb")
    const map = useTexture("/images/white-texture.jpg")
    return (
        <group>
            <instancedMesh
                ref={ref}
                args={[undefined, undefined, 1000]}
                castShadow
                receiveShadow
                geometry={nodes.Curve.geometry}
                {...props}
            >
                <MeshWobbleMaterial factor={1} speed={10} />
                <LayerMaterial lighting="standard" side={DoubleSide}>
                    <Texture map={map} />
                </LayerMaterial>
            </instancedMesh>
        </group>
    )
})
