import Audio from "./components/Audio"
import MainStage from "./components/MainStage"
import styled from "@emotion/styled"

const Main = styled.main`
    height: 100vh;
    width: 100vw;
`

function App() {
    return (
        <div className="App">
            <Main>
                <Audio src="i_cant_run_anymore.mp3" />
                <MainStage />
            </Main>
        </div>
    )
}

export default App
