import React, { useEffect, useRef } from "react"
import * as THREE from "three"
import { extend, useFrame } from "@react-three/fiber"
import { Depth, LayerMaterial } from "lamina"
import { Sampler, useGLTF, useTexture } from "@react-three/drei"
import WindLayer from "./WindLayer"
import Perlin from "perlin.js"
import { Hand } from "./Hand"

Perlin.seed(Math.random())
extend({ WindLayer })

export function Grass({
    children,
    strands = 30000,
    meshRefHand = null,
    ...props
}) {
    // const meshRef = useRef(meshRefHand)
    const meshRef = meshRefHand
    const windLayer = useRef(null)
    const handRef = useRef(null)
    const { nodes } = useGLTF("/images/hand.glb")
    const map = useTexture("/images/white-texture.jpg")

    useEffect(() => {
        meshRef.current.geometry.applyMatrix4(
            new THREE.Matrix4().makeRotationX(Math.PI / 2)
        )
        meshRef.current.geometry.applyMatrix4(
            new THREE.Matrix4().makeTranslation(0, 0, 0.5)
        )
    }, [])

    const geomRef = useRef()
    useFrame(() => (windLayer.current.time += 0.005))
    return (
        <>
            {React.cloneElement(children, { ref: geomRef })}
            <instancedMesh
                ref={meshRef}
                args={[undefined, undefined, strands]}
                {...props}
                geometry={nodes.Curve.geometry}
                visible={true}
            >
                <LayerMaterial map={map}>
                    <windLayer
                        args={[{ mode: "multiply" }]}
                        colorA={"#8D5524"}
                        colorB={"#E0AC69"}
                        noiseScale={10}
                        noiseStrength={5}
                        length={1.2}
                        sway={0.75}
                        ref={windLayer}
                    />
                </LayerMaterial>
            </instancedMesh>
            <Hand position={[0, -1000, 0]} ref={handRef} />
            <group>
                <Sampler
                    transform={({ position, normal, dummy: object }) => {
                        const p = position.clone().multiplyScalar(2)
                        const n = Perlin.simplex3(...p.toArray())
                        object.scale.setScalar(
                            THREE.MathUtils.mapLinear(n, -1, 1, 0.3, 1) * 0.1
                        )
                        object.position.copy(position)
                        object.lookAt(normal.add(position))
                        object.position.z = object.position.z - 0.01
                        object.rotation.y +=
                            Math.random() - 0.5 * (Math.PI * 0.5)
                        object.rotation.z +=
                            Math.random() - 0.5 * (Math.PI * 0.5)
                        object.rotation.x +=
                            Math.random() - 0.5 * (Math.PI * 0.5)
                        object.updateMatrix()
                        return object
                    }}
                    mesh={geomRef}
                    instances={meshRef}
                    count={strands}
                />
            </group>
        </>
    )
}
