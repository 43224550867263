import {
    AdaptiveDpr,
    AdaptiveEvents,
    Billboard,
    Bvh,
    CameraShake,
    Environment,
    GradientTexture,
    Html,
    Loader,
    Plane,
    Scroll,
    ScrollControls,
    useScroll,
} from "@react-three/drei"
import { Suspense, useRef, useState } from "react"
import { Canvas, useFrame, useThree } from "react-three-fiber"
import { BasicShadowMap, PCFSoftShadowMap } from "three"
import Quote from "./Quote"
import { Stats } from "@react-three/drei"

import HandPlain from "./HandPlain"
import {
    DepthOfField,
    EffectComposer,
    Noise,
    Pixelation,
} from "@react-three/postprocessing"

import { default as CSSLoader } from "./Loader"
import Tree, { TreePreload } from "./Tree"
import Audio from "./Audio"

import sunset from "../images/venice_sunset_1k.hdr"
function Content(props) {
    const sd = useScroll()
    const actOneRef = useRef()
    const actTwoRef = useRef()
    const shakeRef = useRef()
    const [showEnv, setShowEnv] = useState(true)
    useFrame(() => {
        const b = sd.range(0, 1 / 2)
        if (b >= 0.99) {
            if (showEnv) {
                setShowEnv(false)
            }
            if (!showEnv) {
                actOneRef.current.visible = false
                actTwoRef.current.visible = true
                shakeRef.current.setIntensity(0)
            }
        } else {
            if (!showEnv) {
                setShowEnv(true)
            }
            if (showEnv) {
                actOneRef.current.visible = true
                actTwoRef.current.visible = false
                shakeRef.current.setIntensity(1)
            }
        }
    })
    return (
        <>
            <group ref={actOneRef}>
                <group>
                    <Quote />
                </group>
                <group>
                    <HandPlain />
                </group>
            </group>
            <group visible={false} ref={actTwoRef}>
                <Tree />
            </group>
            <CameraShake
                ref={shakeRef}
                maxRoll={0.1}
                maxPitch={0.1}
                maxYaw={0.1}
            />
            {showEnv && <Environment files={sunset} />}
        </>
    )
}

export default function MainStage(props) {
    return (
        <Canvas
            shadows={{ type: PCFSoftShadowMap }}
            style={{
                background: `black `,
            }}
            camera={{ position: [1.162, 0.112, -0.05], near: 0.01 }}
        >
            <AdaptiveDpr pixelated />
            <Suspense fallback={<CSSLoader />}>
                <TreePreload />
                <ScrollControls pages={16} damping={0.3}>
                    <Content />
                </ScrollControls>
            </Suspense>
        </Canvas>
    )
}
