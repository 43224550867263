import {
    Billboard,
    Gltf,
    Html,
    OrbitControls,
    Plane,
    Svg,
    Text,
    useGLTF,
    useScroll,
    useTexture,
} from "@react-three/drei"
import { useEffect, useRef, useState } from "react"
import { useFrame, useThree } from "react-three-fiber"
import {
    AmbientLight,
    BoxHelper,
    PointLightHelper,
    RepeatWrapping,
    RGBAFormat,
    Vector2,
} from "three"
import useIsMobile from "../hooks/useIsMobile"
import TreeImg from "../images/Tree.svg"

function Light(props) {
    const group = useRef()
    const data = useScroll()
    const [rotation, setRotation] = useState(0)
    useFrame(() => {
        const a = data.range(1 / 2, 1 / 3)
        setRotation(-a * 3)
    })

    return (
        <group ref={group} position={[0, 0, 0]} rotation={[0, rotation, 0]}>
            <pointLight
                castShadow
                receiveShadow
                position={[-2, 4, -2]}
                intensity={4}
                shadow-mapSize={new Vector2(512 * 2, 512 * 2)}
            />
        </group>
    )
}

export function TreePreload(props) {
    const planeTexture = useTexture(
        "/images/white-texture.jpg",
        (planeTexture) => {
            planeTexture.wrapS = planeTexture.wrapT = RepeatWrapping
            planeTexture.wrapS = planeTexture.wrapT = RepeatWrapping
            planeTexture.repeat.set(3, 3)
        }
    )
    const gltf = useGLTF("/images/Tree.glb")
    return null
}

export default function Tree(props) {
    const { viewport, camera } = useThree()
    const data = useScroll()
    const [zpos, setZpos] = useState(0)
    const [opacity, setOpacity] = useState(1)
    const textRef = useRef()
    const planeMaterialRef = useRef()
    const isMobile = useIsMobile()

    const planeTexture = useTexture(
        "/images/white-texture.jpg",
        (planeTexture) => {
            planeTexture.wrapS = planeTexture.wrapT = RepeatWrapping
            planeTexture.wrapS = planeTexture.wrapT = RepeatWrapping
            planeTexture.repeat.set(3, 3)
        }
    )
    const titleTexture = useTexture(
        "/images/white-texture.jpg",
        (planeTexture) => {
            planeTexture.wrapS = planeTexture.wrapT = RepeatWrapping
            planeTexture.wrapS = planeTexture.wrapT = RepeatWrapping
            planeTexture.repeat.set(10, 10)
        }
    )
    useFrame(() => {
        const b = data.range(2 / 3, 1 / 3)
        setZpos(b * viewport.height)
        setOpacity(b)
        planeMaterialRef.current.opacity = 1 - b
        textRef.current.opacity = 0
        textRef.current.format = RGBAFormat
    })
    return (
        <group position={[0, -0.5, 0]} rotation={[0, Math.PI / 2, 0]}>
            <Html fullscreen>
                <div
                    style={{
                        position: "absolute",
                        top: "1580vh",
                        left: "4vw",
                        width: "40vw",
                        color: "white",
                        //background: "#DCD0B9",
                        padding: "10px",
                        borderRadius: "10px",
                        minWidth: "300px",

                        mixBlendMode: "difference",
                    }}
                >
                    <p>
                        Night Forest Press contact{" "}
                        <a href="mailto:editor@nightforestpress.com">
                            editor@nightforestpress.com
                        </a>
                    </p>
                    jimy dawn at{" "}
                    <a href="mailto:jimy@jimydawn.ca">jimy@jimydawn.ca</a>
                    <p>
                        twitter{" "}
                        <a href="https://twitter.com/jimydawn">@jimydawn</a>
                    </p>
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: "1630vh",
                        left: "4vw",
                        width: "40vw",
                        color: "white",
                        //background: "#DCD0B9",
                        padding: "10px",
                        borderRadius: "10px",
                    }}
                >
                    Purchase from the{" "}
                    <a
                        target="_new"
                        href="https://www.nightforestpress.com/shop"
                    >
                        Night Forest Press shop
                    </a>
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: "1620vh",
                        left: "4vw",
                        width: "40vw",
                        color: "white",
                        //background: "#DCD0B9",
                        padding: "10px",
                        borderRadius: "10px",
                    }}
                >
                    Projects{" "}
                    <a target="_new" href="https://sixpoints.bandcamp.com">
                        bandcamp
                    </a>
                </div>
            </Html>

            <Plane
                receiveShadow
                castShadow
                args={[viewport.width * 2.1, viewport.height * 1.5]}
                position={[0, 0, 0]}
                rotation={[-Math.PI / 2, 0, 0]}
            >
                <meshStandardMaterial
                    ref={planeMaterialRef}
                    color="black"
                    map={planeTexture}
                    bumpMap={planeTexture}
                    bumpScale={0.01}
                    roughness={0.5}
                    //opacity={opacity}
                    transparent={true}
                />
            </Plane>
            <group scale={isMobile ? [0.75, 0.75, 0.75] : [1, 1, 1]}>
                <Gltf
                    src="/images/Tree2.glb"
                    position={[-0.4, 0, 0]}
                    scale={[3, 3, 5]}
                    castShadow
                ></Gltf>
                <Gltf
                    src="/images/Title.glb"
                    position={[-0, 0, 0]}
                    scale={[1.5, 1.5, 0.2]}
                    inject={
                        <meshStandardMaterial
                            ref={textRef}
                            color="#ffffee"
                            map={titleTexture}
                            bumpMap={titleTexture}
                            bumpScale={0.01}
                            roughness={0.5}
                            opacity={opacity}
                            transparent={true}
                        />
                    }
                ></Gltf>
            </group>

            <Light startPage={props.startPage} numPages={props.numPages} />
        </group>
    )
}
