import React, { useState } from "react"
import { ReactComponent as SpeakerOn } from "../images/speaker_on.svg"
import { ReactComponent as SpeakerOff } from "../images/speaker_off.svg"
import { set } from "lodash"

import "../css/audio.css"

export default function Audio(props) {
    const [play, setPlay] = useState(false)
    let Image = SpeakerOn

    function toggleSound() {
        if (play) {
            setPlay(false)
        } else {
            setPlay(true)
        }
    }

    if (props.off) {
        setPlay(false)
        Image = SpeakerOff
        window.document.getElementById("player").play()
    } else {
        if (window && window.document.getElementById("player")) {
            if (play) {
                Image = SpeakerOff
                window.document.getElementById("player").play()
            } else {
                Image = SpeakerOn
                window.document.getElementById("player").pause()
            }
        }
    }

    return (
        <div>
            <audio id="player" loop>
                <source src={props.src} type="audio/mpeg" />
            </audio>
            <div
                className="clickable"
                id="soundControler"
                onClick={() => {
                    toggleSound()
                }}
            >
                <Image />
            </div>
        </div>
    )
}
