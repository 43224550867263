import {
    Billboard,
    Gltf,
    Html,
    OrbitControls,
    Scroll,
    Stars,
    Text,
    useScroll,
} from "@react-three/drei"
import { useState } from "react"
import { useFrame } from "react-three-fiber"
import useIsMobile from "../hooks/useIsMobile"

const quotes = [
    <>Let’s praise this mutilated world though we are livid.</>,
    <>
        The omens sing in ways that are fresh
        <br /> and feral to our bones as we grind <br />
        ourselves deep into the loam.
    </>,
    <>Bears guide us to unknown temples.</>,
    <>
        The empty homes tower above the altered landscape <br /> that keep love
        in its place.
    </>,
    <>Words cannot say what the body really wants to do.</>,
    <>
        Moisten the ground and know the mud <br /> of our lives is ripe with
        undeniable promise.
    </>,
]

const quoteColors = [
    "#ffffff",
    "#d8c3c0",
    "#b18985",
    "#88534e",
    "#5e1f1d",
    "#480000",
]

function QuoteText(props) {
    const text = props.children
    const offset = props.offset
    const isMobile = useIsMobile()

    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                opacity: 0.5,
                //mixBlendMode: "difference",
            }}
        >
            <h1
                style={{
                    //color: "#969696",
                    color: props.color ? props.color : "white",
                    width: "100vw",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: isMobile ? "5.5vw" : "4vw",
                    //filter: "invert(1) contrast(400%) grayscale(100%)",
                }}
            >
                {text}
            </h1>
        </div>
    )
}

export default function Quote(props) {
    const data = useScroll()
    const [pos, setPos] = useState([4, 0, -15])

    useFrame(() => {
        const b = data.range(0, 2 / 3)
        setPos([4 - b * 7, 7 - b * 6, -18 + b * 10])
    })

    let count = -1
    const items = []
    quotes.map((q) => {
        count++
        return items.push(<QuoteText color={quoteColors[count]}>{q}</QuoteText>)
    })
    return (
        <>
            <color attach="background" args={["#0c1445"]} />

            <Scroll>
                <Stars
                    radius={0.01}
                    depth={100}
                    count={2000}
                    factor={1}
                    saturation={0}
                    speed={1}
                ></Stars>
                <ambientLight />
                <Billboard>
                    <Html fullscreen>{items}</Html>
                </Billboard>
            </Scroll>
        </>
    )
}
