import { CameraShake, Environment, Sky, useScroll } from "@react-three/drei"
import { useRef, useState } from "react"
import { useThree, useFrame } from "react-three-fiber"
import { Grass } from "./Grass"
import { LovelyPlain } from "./LovelyPlain"
import { Cloud } from "./Cloud"
import { Landscape } from "./Landscape"

function Clouds() {
    return (
        <group>
            <Cloud position={[-10, -2, -10]} speed={0.2} opacity={0.4} />
            <Cloud position={[10, 6, -15]} speed={0.2} opacity={0.25} />
            <Cloud position={[0, 1, 0]} speed={0.2} opacity={0.2} />
            <Cloud position={[-10, -6, 15]} speed={0.2} opacity={0.3} />
            <Cloud position={[10, 6, 10]} speed={0.2} opacity={0.25} />
        </group>
    )
}

export default function HandPlain(props) {
    const three = useThree()
    const sd = useScroll()
    const ref = useRef()
    const cloudRef = useRef()
    const skyRef = useRef()
    const orbRef = useRef()
    const meshRefHand = useRef()

    useFrame(() => {
        const a = sd.range(0, 1 / 8) + 0.00001
        const b = sd.range(1 / 8, 1 / 8) + 0.00001
        const c = sd.range(1 / 4, 1 / 4) + 0.00001
        ref.current.position.y = -8 + a * 8
        cloudRef.current.position.y = -20 + a * 20
        skyRef.current.material.uniforms.turbidity.value = a * 10 - 2
        orbRef.current.rotation.z = orbRef.current.rotation.z + 0.002
        if (a > 0.7) {
            skyRef.current.material.uniforms.rayleigh.value = (a - 0.7) * a
        } else {
            skyRef.current.material.uniforms.rayleigh.value = 0
        }

        if (b > 0) {
            ref.current.position.x = b - 0.4
        }
        if (c > 0.001) {
            //orbRef.current.rotation.z = orbRef.current.rotation.z + c * 0.01
            ref.current.position.x = b - 0.4 + c / 1.8
            ref.current.position.y = c / 9
        }
        if (c > 0.49875371859296486) {
            meshRefHand.current.visible = false
        } else {
            meshRefHand.current.visible = true
        }
    })

    return (
        <group ref={ref} position={[0, 0, -0.045]}>
            <group ref={orbRef} rotation={[-Math.PI / 2, 0, 0]}>
                <Grass meshRefHand={meshRefHand} strands={5000}>
                    <Landscape />
                </Grass>
            </group>

            <Sky
                ref={skyRef}
                azimuth={-0.4}
                inclination={1}
                turbidity={10}
                rayleigh={1}
            />

            <group ref={cloudRef} opacity={0}>
                <Clouds />
            </group>
        </group>
    )
}
